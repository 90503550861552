<template>
  <div>developers</div>
</template>

<script>
export default {
    name: 'developers',

}
</script>

<style>

</style>